import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTechnicalFormatAsync,
  putInitFormatAsync,
  finishInspectAsync,
  AnularInspeccion,
} from "../../api/technicalFormat";

export const getTechnicalFormat = createAsyncThunk(
  "technicalFormat/getData",
  async (dataParams) => {
    const { body } = await getTechnicalFormatAsync(dataParams);
    return body;
    //return formatos;
  }
);

export const finishInspect = createAsyncThunk(
  "technicalFormat/finishInspect",
  async (dataParams, state) => {
    const { technicalFormato } = state.getState();
    const {
      idConfig,
      horaFin,
      fechaFin,
      textoConclusiones,
      textoRecomendacion,
    } = technicalFormato.formato;

    try {
      const { body } = await finishInspectAsync({
        idConfig,
        horaFin,
        fechaFin,
        textoConclusiones,
        textoRecomendacion,
      });

      return body;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const anularInspeccion = createAsyncThunk(
  "technicalFormat/anularInspeccion",
  async (dataParams, state) => {
    try {
      const { body } = await AnularInspeccion({
        idConfig: dataParams.idFormato,
      });

      return body;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const addFechaInit = createAsyncThunk(
  "technicalFormat/addFechaInit",
  async (dataParams, state) => {
    try {
      const { technicalFormato } = state.getState();
      const { idConfig } = technicalFormato.formato;
      const { body } = await putInitFormatAsync({ dataParams, idConfig });
      
      return body;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);
