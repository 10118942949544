import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { updateCorrectivo } from "../../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
const CheckCorrectivo = ({ i, indiceSubcampo, textDescripcion }) => {
  const [checkcorrectivo, setcheckcorrectivo] = useState(false);
  const dispatch = useDispatch();

  const handleCorrectivo = (e, indice, indiceSubcampo, text) => {
    dispatch(
      updateCorrectivo({
        ...indice,
        requiereCorrectivoPregunta: e.target.checked,
      })
    );
    setcheckcorrectivo(e.target.checked);
    if (e.target.checked) {
      if (
        textDescripcion === undefined ||
        textDescripcion === null ||
        textDescripcion.trim() === ""
      ) {
        dispatch(
          updateCorrectivo({
            ...indice,
            requiereCorrectivoPregunta: !e.target.checked,
          })
        );
        setcheckcorrectivo(!e.target.checked);
        alert("Debes agregar una descripción a este campo");
        return;
      }
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkcorrectivo}
          onChange={(e) =>
            handleCorrectivo(e, {
              indiceParams: i,
              indiceSubcampo: indiceSubcampo,
              text: textDescripcion,
            })
          }
        />
      }
      label="Generar Ticket"
    />
  );
};

export default CheckCorrectivo;
