import {
  Paper,
  InputLabel,
  FormControl,
  Select,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";

import {
  updateConclusion,
  addChecked,
  addParamData,
  addRecomendacion,
  checkedTicket,
} from "../../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
import useNetwork from "../../../../../../../../hooks/useNetwork/UseNetwork";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const Conclusion = ({ estado, idGrupo }) => {
  const dispatch = useDispatch();
  const { campo } = useSelector((state) => state.menuGroup);
  const { offlineStore, PermiteGenerarTicketGrupo } = useSelector(
    (state) => state.auth
  );

  const handleChange = (event) => {
    dispatch(updateConclusion(event.target.value));
  };
  const networkState = useNetwork();
  const { online } = networkState;
  const handleChecked = (event) => {
    dispatch(addChecked(event.target.checked));

    if (event.target.checked === false) {
      dispatch(addRecomendacion(null));
    }
  };
  const handleRecomend = (event) => {
    dispatch(addRecomendacion(event.target.value));
  };

  useEffect(() => {
    if (idGrupo && offlineStore === false && online) {
      dispatch(addParamData());
    }
    if (!idGrupo && offlineStore === true) {
      dispatch(updateConclusion("Sin deficiencias"));
      dispatch(checkedTicket(false));
    }
  }, [idGrupo, dispatch]);
  //idGrupo

  return (
    <Paper variant="outlined" square sx={{ padding: "0.5rem" }}>
      <Typography variant="h7" component="div">
        Conclusión
      </Typography>
      <Grid
        spacing={5}
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ marginTop: "0.8rem" }}
      >
        <Grid item xs={4} sm={4} md={6}>
          <FormControl fullWidth>
            {estado === "ATENDIDA" ||
            estado === "EN PROGRAMACIÓN" ||
            campo?.parametros[0]?.ticket ? (
              <div></div>
            ) : (
              <InputLabel variant="standard" id="demo-simple-select-label1">
                Seleccionar
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label1"
              id="seleccionar-conclusion"
              value={campo?.conclusion ? campo?.conclusion : null}
              label="Seleccionar"
              onChange={(e) => handleChange(e)}
              disabled={
                estado === "ATENDIDA" ||
                estado === "EN PROGRAMACIÓN" ||
                campo?.parametros[0]?.ticket
              }
            >
              <MenuItem value="Sin deficiencias">Sin deficiencias</MenuItem>
              <MenuItem value="Deficiencias Leves">Deficiencias Leves</MenuItem>
              <MenuItem value="Deficiencias Graves">
                Deficiencias Graves
              </MenuItem>
              <MenuItem value="Muy Graves">Muy Graves</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          {campo?.parametros[0]?.ticket ? (
            <Typography
              variant="h6"
              component="div"
              sx={{
                padding: "1rem",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Ticket : {campo?.parametros[0]?.ticket}
            </Typography>
          ) : null}
          {PermiteGenerarTicketGrupo && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      campo?.solicitaTicket ? campo.solicitaTicket : false
                    }
                    onChange={handleChecked}
                    disabled={
                      estado === "ATENDIDA" ||
                      estado === "EN PROGRAMACIÓN" ||
                      campo?.parametros[0]?.ticket
                    }
                  />
                }
                label="Solicito generar Ticket correctivo"
              />
            </FormGroup>
          )}
        </Grid>

        {campo?.solicitaTicket && (
          <Grid item xs={4} sm={8} md={12}>
            <TextField
              value={campo?.recomendacion}
              variant={"outlined"}
              size="large"
              label="Conclusión"
              inputProps={{ maxLength: 500 }}
              helperText={`${
                campo?.recomendacion ? campo?.recomendacion.length : 0
              }/500`}
              multiline
              fullWidth
              sx={{
                marginTop: "1rem",
                margin: "0.5rem 0",
              }}
              onChange={handleRecomend}
              disabled={
                estado === "ATENDIDA" ||
                estado === "EN PROGRAMACIÓN" ||
                campo?.parametros[0]?.ticket
              }
              rows={4}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default Conclusion;
