import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadFile, deleteFile } from "../../helpers/azure/azureStorage";
import {
  postFormat,
  getFormatAsync,
  updateDataAsync,
  actualizarParamsAsync,
} from "../../api/format";

export const sendFormat = createAsyncThunk(
  "formato/sendFormat",
  async (dataParams, state) => {
    const { formato } = state.getState();

    let arrobjCopy = [];
    const secciones = formato.formato.secciones.map((e) => e);

    secciones.forEach((element) => {
      //separar mi objeto tipo file en un array [{}]
      element.grupos.forEach((grupo) => {
        grupo.parametros.forEach((parametro) => {
          if (
            typeof parametro.fieldValue === "object" ||
            parametro.fileDb?.uuidFileName
          ) {
            arrobjCopy.push({ ...parametro });
          }

          if (parametro.subCampos) {
            parametro.subCampos?.forEach((subCampos) => {
              if (
                typeof subCampos.fieldValue === "object" ||
                subCampos.fileDb?.uuidFileName
              ) {
                arrobjCopy.push({ ...subCampos });
              }
            });
          }
        });
      });
    });

    async function getData(arrCopy) {
      let auxData = {
        fileName: null,
        peso: null,
        uuidFileName: null,
        extension: null,
      };

      for await (const field of arrCopy) {
        const { fieldValue, fileDb, id } = field;

        const arrFile =
          fileDb?.uuidFileName && fileDb?.uuidFileName?.split(",");

        if (Array.isArray(fieldValue) === false) {
          const { name, size } = fieldValue;
          const extension = "." + name?.split(".").pop();
          try {
            if (fileDb?.uuidFileName && fieldValue !== "{}") {
              //si hay existe en db eliminar
              await actualizarParamsAsync(id);
              await deleteFile(fileDb.uuidFileName);
            }
          } catch (error) {
            console.log(error, "error al eliminar archivo");
          } finally {
            // si existe no tocar,si no existe subir

            if (fileDb?.uuidFileName && fieldValue === "{}") {
              auxData = fileDb;
            }
            if (typeof fieldValue === "object" && fieldValue !== "{}") {
              const { uuidFileName } = await uploadFile(fieldValue, name);

              auxData = {
                fileName: name,
                peso: size.toString(),
                extension,
                uuidFileName,
              };
            }

            field.fileDb = auxData;
          }
        }

        if (arrFile && arrFile.length > 1 && fieldValue !== "{}") {
          try {
            if (fileDb?.uuidFileName && fieldValue !== "{}") {
              await actualizarParamsAsync(id);
              for await (const element of arrFile) {
                await deleteFile(element);
              }
            }

            field.fileDb = {
              fileName: null,
              peso: null,
              uuidFileName: null,
              extension: null,
            };
          } catch (error) {
            console.log("error al eliminar archivo");
          }
        }
        if (Array.isArray(fieldValue)) {
          let names = [];
          let sizes = [];
          let uuidFileNames = [];
          let extensions = [];

          for await (const file of fieldValue) {
            const { name, size } = file;
            const extension = "." + name?.split(".").pop();
            if (name) names.push(name);
            if (size) sizes.push(size);
            if (extension) extensions.push(extension);
            try {
              if (typeof fieldValue === "object") {
                const { uuidFileName } = await uploadFile(file, name);
                uuidFileNames.push(uuidFileName);
              }
            } catch (error) {
              console.log(error, "error al eliminar archivo");
            }
          }
          auxData = {
            fileName: names.join(),
            peso: sizes.join(),
            extension: extensions.join(),
            uuidFileName: uuidFileNames.join(),
          };

          field.fileDb = auxData;
        }
      }
      return arrCopy;
    }

    return getData(arrobjCopy);
  }
);

export const sendData = createAsyncThunk(
  "formato/sendData",
  async (dataParams, state) => {
    const { formato } = state.getState();
 
    try {
      const res = await postFormat({
        dataFormato: formato.formato,
        idFormatos: formato.idFormatos,
        epps:formato.epps
      });

      if (res.success === false && res.body?.nombre !== null) {
        throw Error("Ya existe un formato con ese nombre");
      }
      return res.success;
    } catch (err) {
      // if (err.message === "Ya existe un formato con ese nombre")
      for await (const seccion of formato.formato.secciones) {
        for await (const grupo of seccion.grupos) {
          for await (const parametro of grupo.parametros) {
            if (parametro.fieldValue === "{}") {
              const { fileDb } = parametro;
              await deleteFile(fileDb.uuidFileName);
            }
            if (parametro.subCampos) {
              for await (const subcampos of parametro.subCampos) {
                if (subcampos.fieldValue === "{}") {
                  const { fileDb } = subcampos;
                  await deleteFile(fileDb.uuidFileName);
                }
              }
            }
          }
        }
      }

      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const updateData = createAsyncThunk(
  "formato/updateData",
  async (dataParams, state) => {
    try {
      const { formato } = state.getState();

      for await (const deleteObj of formato.idFormatos) {
        if (deleteObj.arrFileDelete) {
          for await (const uuid of deleteObj.arrFileDelete) {
            await deleteFile(uuid);
          }
        }
      }

      const arrId = formato.idFormatos.map((e) => e.id);

      const res = await updateDataAsync({
        dataFormato: { ...formato.formato, idFormatos: arrId },
        idFormat: dataParams.idFormat,
        epps:formato.epps
      });

      return res.success;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const getFormat = createAsyncThunk(
  "format/getData",
  async (dataParams) => {
    const { body } = await getFormatAsync(dataParams);
    return body;
    //return formatos;
  }
);

/*
export const deleteFieldAzure = createAsyncThunk(
  "format/deleteAzure",
  async (dataParams, state) => {
    const { formato } = state.getState();
    const { formato: formatoOBjt } = formato;
    const { indiceSection, indiceGroup, indiceParams } = dataParams;
    if (
      formatoOBjt.secciones[indiceSection].grupos[indiceGroup].parametros[
        indiceParams
      ].fieldValue === "{}"
    ) {
      const arrFile =
        formatoOBjt.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].fileDb.uuidFileName.split(",");

      for await (const file of arrFile) {
        await deleteFile(file);
      }
    }

    return dataParams;
  }
);*/
