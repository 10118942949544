import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {addCampo} from "../../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
import {postData} from "../../../../../../../../store/menuGroupReducer/menuGroupReducer.action";
import useNetwork from "../../../../../../../../hooks/useNetwork/UseNetwork";

import { TreeView, TreeItem } from "@mui/x-tree-view";

const MenuData = ({data, estilo, ubigeoIndex, index}) => {
    const distpatch = useDispatch();
    const networkState = useNetwork();
    const {offlineStore} = useSelector((state) => state.auth);
    const {online} = networkState;
    const handleClick = () => {
        const {IdArea, IdEdificio, IdInmueble, IdNivel} = ubigeoIndex;

        distpatch(
            addCampo({
                ubigeoIndex,
                parametros: [],
            })
        );

        if (offlineStore === false && online && IdArea) {
            //VALIDAR QUE TENGA INMUEBLE Y EDIFICIO
            distpatch(
                postData({
                    filter: {
                        IdArea,
                        IdEdificio,
                        IdInmueble,
                        IdNivel,
                    },
                    page: 0,
                    pageSize: 100,
                })
            );
        }
        if (offlineStore === false && online && IdNivel && IdArea === null) {
            distpatch(
                postData({
                    filter: {
                        IdArea,
                        IdEdificio,
                        IdInmueble,
                        IdNivel,
                    },
                    page: 0,
                    pageSize: 100,
                })
            );
        }
    };

    return (
        <>
            <TreeItem onClick={handleClick} nodeId={index} label={data.label} itemId={index}>
                {data.children && (
                    <>
                        {data.children.map((el, indice) => (
                            <MenuData
                                data={el}
                                estilo={estilo ? 9 + estilo : 9}
                                key={el.label + indice}
                                ubigeoIndex={{
                                    IdArea: el.IdArea,
                                    IdEdificio: el.IdEdificio,
                                    IdInmueble: el.IdInmueble,
                                    IdNivel: el.IdNivel,
                                    label: `${ubigeoIndex.label}/` + el.label,
                                }}
                                index={"submenu" + index + indice}
                            />
                        ))}
                    </>
                )}
            </TreeItem>
        </>
    );
};

export default MenuData;
