import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { finishInspect } from "../../../../../../../../../store/technicalFormatoReducer/technicalFormatReducer.action";
import {
  addConclusionFinalizacionInspeccion,
  addRecomendacionFinalizacionInspeccion,
} from "../../../../../../../../../store/technicalFormatoReducer/technicalFormatReducer.reducer";

import { Button, TextField } from "@mui/material";
const FooterModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { formato } = useSelector((state) => state.technicalFormato);

  const handleSubmitAddConclusiones = (e) => {
    dispatch(addConclusionFinalizacionInspeccion(e.target.value));
  };
  const handleSubmitAddRecomendacion = (e) => {
    dispatch(addRecomendacionFinalizacionInspeccion(e.target.value));
  };

  const validar = () => {
    if (
      formato?.textoConclusiones === undefined ||
      formato?.textoConclusiones === null
    ) {
      alert("Ingresar Conclusiones y Recomendaciones");
      return false;
    }
    if (
      formato?.textoRecomendacion === undefined ||
      formato?.textoRecomendacion === null
    ) {
      alert("Ingresar Conclusiones y Recomendaciones");
      return false;
    }
    return true;
  };

  const handleSend = () => {
    const validado = validar();
    if (validado) dispatch(finishInspect());
  };

  return (
    <div className="button-format_modal_finalizar">
      <h2 id="parent-modal-title">Finalizar Inspección</h2>
      <p id="parent-modal-description">
        Es necesario que complete estos dos campos para generar el informe final
        de la inspección.
      </p>
      <TextField
        id="outlined-multiline-static"
        label="Conclusiones"
        helper
        multiline
        rows={4}
        inputProps={{ maxLength: 500 }}
        helperText={`${
          formato?.textoConclusiones ? formato?.textoConclusiones.length : 0
        }/500`}
        margin="normal"
        value={formato?.textoConclusiones}
        sx={{ width: 999, fullWidth: "100%" }}
        onChange={handleSubmitAddConclusiones}
      />
      <TextField
        id="outlined-multiline-static"
        label="Recomendaciones"
        multiline
        rows={4}
        inputProps={{ maxLength: 500 }}
        helperText={`${
          formato?.textoRecomendacion ? formato?.textoRecomendacion.length : 0
        }/500`}
        margin="normal"
        value={formato?.textoRecomendacion}
        sx={{ width: 999, fullWidth: "100%" }}
        onChange={handleSubmitAddRecomendacion}
      />
      {moment(formato?.fechaFin).utc("America/Lima").format("DD/MM/YYYY")} -
      {moment(formato?.horaFin).utc("America/Lima").format("h:mm:ss a")}
      <div className="content-btn">
        <Button
          onClick={async () => await handleSend()}
          variant="contained"
          size="small"
        >
          Guardar
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default FooterModal;
