import { useEffect, useState, useRef } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequireEpp,
  setEpps,
} from "../../../../../store/formatReducer/formatoReducer.reducer";

import Button from "@mui/material/Button";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { obtenerEppsAsync } from "../../../../../api/format/index";

const CheckEpps = () => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const [checkedOpcion, setCheckedOpcion] = useState([]);
  const { requireEpp, epps } = useSelector((state) => state.formato);
  //  const [checkedArray, setCheckedArray] = useState([]);
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    if (requireEpp === true) {
      setOpen(true);
      return;
    }

    if (event.target.checked === false) {
      dispatch(setEpps([]));
      setCheckedOpcion([]);
    }

    setChecked(event.target.checked);
    setOpen(event.target.checked);

    dispatch(setRequireEpp(event.target.checked));
  };

  useEffect(() => {
    setChecked(requireEpp);
  }, [setChecked, requireEpp]);

  useEffect(() => {
    if (epps?.length > 0) {
      dispatch(setRequireEpp(true));
    }else{
      dispatch(setRequireEpp(false));
    }
  }, [epps]);

  const handleClose = (data) => {
    if (data === false) {
      dispatch(setEpps([]));
      setCheckedOpcion([]);
      dispatch(setRequireEpp(false));
      setChecked(false);
    }
    setOpen(false);

    if (data === true) {
      if (epps?.length === 0) {
        dispatch(setRequireEpp(false));
      }
    }
  };

  return (
    <>
      <FormControlLabel
        label="Agregar EPPS(Equipos de Protección Personal)"
        control={<Checkbox checked={checked} onChange={handleChange} />}
      />

      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        checkedOpcion={checkedOpcion}
        setCheckedOpcion={setCheckedOpcion}
      />
    </>
  );
};
function ConfirmationDialogRaw(props) {
  const { onClose, open, checkedOpcion, setCheckedOpcion, ...other } = props;
  const { epps } = useSelector((state) => state.formato);

  const radioGroupRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getEppsAsync();
  }, [setOptions]);

  const getEppsAsync = async () => {
    setLoading(true);
    const res = await obtenerEppsAsync();
    if (res.code === 200 && res.body.length > 0) {
      setOptions(res.body);
      if (epps !== null && epps.length > 0) {
        setCheckedOpcion(res.body.filter((item) => epps.includes(item.id)));
      }
    }

    //  setOptions(res.data);
    setLoading(false);
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  const handleChange = (value) => () => {
    const currentIndex = checkedOpcion.indexOf(value);
    const newChecked = [...checkedOpcion];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let dataChecked = newChecked.map((item) => item.id);
    dispatch(setEpps(dataChecked));
    setCheckedOpcion(newChecked);
  };

  const handleOk = (event) => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Equipos de Protección Personal</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <div>...cargando</div>
        ) : (
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {options.map((option, index) => (
              // <FormControlLabel
              //   value={option}
              //   key={option}
              //   control={<Radio />}
              //   label={option}
              // />

              <ListItem key={index + "option"} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleChange(option)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkedOpcion.indexOf(option) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": option }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={index + "optionLabel"}
                    primary={`${option.nombre}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}{" "}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          autoFocus
          onClick={handleCancel}
        >
          Borrar
        </Button>
        <Button variant="outlined" color="success" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CheckEpps;
