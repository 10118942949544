import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import CustomAlert from "../../../../../components/customAlert/CustomAlert";
import ProgressLoading from "../../../../../components/progressLoading/ProgressLoading";
import { Typography, Button, Paper } from "@mui/material";
import { clearStateParametros } from "../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
import MenuList from "./components/menuList/MenuList";
import MenuSection from "./components/menuSection/MenuSection";
import TableGroup from "./components/tableGroup/TableGroup";
import ParamsData from "./components/paramsData/ParamsData";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./ListFlat.css";

const ListFlat = () => {
  const [aux, setAux] = useState(false);
  const [open, setOpen] = useState(false);
  const [idGrupo, setIdGrupo] = useState(null);
  const [fechaGrupo, setFechaGrupo] = useState(null);
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [stateList, setStateList] = useState([true, false, false]);

  const { loadingConfig,confingInspection,errorConfig} = useSelector(
    (stateConfig) => stateConfig.configInspection
  );

  const dispatch = useDispatch();
  const { loading, formato } = useSelector(
    (ticketCorrectivo) => {
      return ticketCorrectivo.technicalFormato;
    }
  );
  const { campo } = useSelector(
    (ticketCorrectivo) => ticketCorrectivo.menuGroup
  );
  const toggleAccordion = (e, index) => {
    const data = stateList.map((e, i) => (i === index ? true : false));
    setStateList(data);
  };
  const handleClick = () => {
    setIdGrupo(null);

    if (campo?.nombreGrupo && campo?.ubigeoIndex?.IdNivel) {
      setAux(true);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessage = () => {
    if (!campo?.ubigeoIndex?.IdNivel) return "Seleccione  el Piso";
    if (!campo?.nombreGrupo) return "Seleccione un Grupo";
  };

  const handleRegresar = () => {
    setAux(false);
    setOpen(false);
    // handleSelected();
    //handleToggle();
    let newArray;
    formato.secciones.forEach((e) => {
      if (e.nombre === campo.nombreSeccion) {
        return e.grupos.forEach((e) => {
          if (e.nombre === campo.nombreGrupo) {
            newArray = e.parametros;
          }
        });
      }
    });

    dispatch(clearStateParametros(newArray));
  };
  const handleSelected = (e, nodeIds) => {
    if (
      campo?.ubigeoIndex.IdEdificio &&
      campo?.ubigeoIndex.IdInmueble &&
      campo?.ubigeoIndex.IdNivel
    ) {
      setSelected(nodeIds);
    } else {
      setSelected([]);
      setOpen(true);
    }

    /* if (!campo.nombreSeccion && !campo.nombreGrupo) {
  
      
    } else {
      setSelected(nodeIds);
  
    }*/
  };

  const handleToggle = () => {
    if (!campo.nombreSeccion && !campo.nombreGrupo) setExpanded([]);
  };
  const handleExpand = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    if (!campo.nombreSeccion && !campo.nombreGrupo) setSelected([]);
    if (
      campo?.ubigeoIndex.IdEdificio &&
      campo?.ubigeoIndex.IdInmueble &&
      campo?.ubigeoIndex.IdNivel
    ) {
      setAux(false);
      setOpen(false);

      handleToggle();
    }
    return;
  }, [
    campo?.ubigeoIndex.IdEdificio,
    campo?.ubigeoIndex.IdInmueble,
    campo?.ubigeoIndex.IdNivel,
    campo?.nombreGrupo,
    campo?.nombreSeccion,
  ]);

  return (
    <>
      {loading ? (
        <ProgressLoading />
      ) : (
        <div className="contentToggle">
          <ul className="contentToggle-ul">
            <li
              onClick={(e) => toggleAccordion(e, 0)}
              className={`contentTogle-ul_li ${
                stateList[0] ? "active" : null
              } ListFlat-li`}
            >
              <div className="section-title">
                <span className="section-subSpan"> UBICACIÓN</span>
              </div>
              <div className="section-content">
                <div className="content-displayind">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{
                      textAlign: "left",
                      padding: "1rem",
                      paddingLeft: 0,
                      fontWeight: "bold",
                    }}
                  >
                    UBICACIÓN
                  </Typography>
                  <Paper elevation={5}>
                { loadingConfig===false &&    
                <MenuList
                      idInmueble={formato?.idInmueble}
                      idCliente={formato?.idCliente}
                    />}
                  </Paper>
                </div>
              </div>
            </li>

            <li
              onClick={(e) => toggleAccordion(e, 1)}
              className={`contentTogle-ul_li ${
                stateList[1] ? "active" : null
              } ListFlat-li`}
            >
              <div className="section-title">
                <span className="section-subSpan">
                  {" "}
                  CATEGORÍA / SUB CATEGORÍA:
                </span>
              </div>
              <div className="section-content">
                <div className="content-displayind">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{
                      textAlign: "left",
                      padding: "1rem",
                      paddingLeft: 0,
                      fontWeight: "bold",
                    }}
                  >
                    CATEGORÍA / SUB CATEGORÍA:
                  </Typography>
                  <Paper
                    elevation={5}
                    sx={{ height: "35rem", overflowY: "auto" }}
                  >
                    <TreeView
                      aria-label="file system navigator2"
                      sx={{
                        flexGrow: 1,

                        margin: "0 auto",
                        overflowY: "auto",
                      }}
                      expanded={expanded?.length > 0 ? expanded : []}
                      selected={selected?.length > 0 ? selected : []}
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      onNodeSelect={handleSelected}
                      onNodeToggle={handleExpand}
                    >
                      {formato?.secciones?.map((el, index) => (
                        <MenuSection
                          section={el}
                          key={"sección" + index}
                          indice={index}
                        />
                      ))}
                    </TreeView>
                  </Paper>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => toggleAccordion(e, 2)}
              className={`contentTogle-ul_li ${
                stateList[2] ? "active" : null
              } ListFlat-li2`}
            >
              <div className="section-title">
                <span className="section-subSpan">TABLA</span>
              </div>
              <div className="section-content">
                <div className="content-displayind">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{
                      textAlign: "left",
                      padding: "1rem",
                      paddingLeft: 0,
                      fontWeight: "bold",
                    }}
                  >
                    TABLA
                  </Typography>
                  <Paper style={{ height: "35rem" }} elevation={5}>
                    <Typography
                      variant="h7"
                      component="div"
                      sx={{
                        padding: "1rem",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                      className="ListFlat-TitleTable"
                    >
                      UBICACIÓN :{" "}
                      <span
                        style={{ fontWeight: 200 }}
                        className="ListFlat-SubTitleTable"
                      >
                        {campo?.ubigeoIndex?.label}{" "}
                      </span>{" "}
                      <br />
                      CATEGORÍA-SUB CATEGORÍA :
                      <span
                        style={{ fontWeight: 200 }}
                        className="ListFlat-SubTitleTable"
                      >
                        {campo?.nombreSeccion && ` ${campo.nombreSeccion}`}
                        {campo?.nombreGrupo && ` /${campo.nombreGrupo}`}
                      </span>
                    </Typography>

                    <div style={{ padding: "0.8rem" }}>
                      {!aux ? (
                        formato?.estado === "ATENDIDA" || formato?.estado === "ANULADO" ? null : (
                          <Button
                            variant="contained"
                            onClick={handleClick}
                            className="ListFlat-btnAction"
                          >
                            Nuevo
                          </Button>
                        )
                      ) : (
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleRegresar}
                            className="ListFlat-btnAction"
                          >
                            Volver
                          </Button>
                        </div>
                      )}
                    </div>

                    {!aux ? (
                      <TableGroup setIdGrupo={setIdGrupo} setAux={setAux}  setFechaGrupo={setFechaGrupo}/>
                    ) : (
                      <ParamsData
                        idGrupo={idGrupo}
                        fechaGrupo={fechaGrupo}
                        handleSelected={handleSelected}
                        handleToggle={handleToggle}
                        setAux={setAux}
                      />
                    )}
                  </Paper>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
      {open && (
        <CustomAlert
          tipe={"info"}
          style={{
            position: "absolute",
          }}
          message={handleMessage()}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default ListFlat;
