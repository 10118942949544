import ax from "../../helpers/axiosConfig";
import { encriptar } from "../../helpers/encrypt";


const EDI = process.env.REACT_APP_ENPOINT_EDI;

const INMUEBLE = process.env.REACT_APP_INMUEBLE;

export const getLoginAsync = (data) =>
  ax.get(`/api/Seguridad/Opciones/${data?.IdUser}`);

export const getListaUnidadMantenimientoAsync = (data) =>
  ax.get(`/api/Seguridad/ListarUnidadMantenimiento/${data?.IdCliente}`);

export const getLogoutAsync = () => ax.get(`/api/v1/logout`);

export const postLoginAsync = async (obj) => {
  const data = await encriptar(obj);
  return ax.post(`${EDI}/Autenticacion`, data);
}


export const getListaGrupoMantenimientoAsync = (data) =>
  ax.get(`${INMUEBLE}/api/Administracion/ObtenerGrupoManteminientoxCliente?IdCliente=${data?.IdCliente}`);