import * as React from "react";

import {useSelector, useDispatch} from "react-redux";
import {addCampo} from "../../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
import {postData} from "../../../../../../../../store/menuGroupReducer/menuGroupReducer.action";
import {TreeView, TreeItem} from "@mui/x-tree-view";
import {useLocation} from "react-router-dom";
import useNetwork from "../../../../../../../../hooks/useNetwork/UseNetwork";

const MenuGroup = ({groups, section, indice}) => {
    const distpatch = useDispatch();
    const {loadingConfig, confingInspection, errorConfig} = useSelector(
        (stateConfig) => stateConfig.configInspection
    );

    const {campo} = useSelector((state) => state.menuGroup);

    const {user, offlineStore} = useSelector((state) => state.auth);
    const networkState = useNetwork();
    const {online} = networkState;
    const handleItem = (nombreGrupo, parametros, idUnidadMantenimiento, idGrupoMantenimiento) => {
        const {IdArea, IdEdificio, IdInmueble, IdNivel} = campo.ubigeoIndex;
        if (offlineStore === false && online) {

            distpatch(
                postData({
                    filter: {
                        nombreGrupo: nombreGrupo,
                        idUnidadMantenimiento: idUnidadMantenimiento,
                        idGrupoMantenimiento: idGrupoMantenimiento,
                        nombreSeccion: section,
                        IdArea,
                        IdEdificio,
                        IdInmueble,
                        IdNivel,
                    },
                    page: 0,
                    pageSize: 100,
                })
            );
        }
        distpatch(
            addCampo({
                ...campo,
                nombreGrupo,
                idUnidadMantenimiento,
                idGrupoMantenimiento,
                parametros,
                nombreSeccion: section,
                codigo: confingInspection.codigo,
                usuario: user.NombreUsuario,
                idUsuario: user.countHourMinute,
            })
        );
    };

    return (
        <>
            {groups?.map((element, index) => (
                <TreeItem
                    onClick={() => handleItem(element.nombre, element.parametros, element.idUnidadMantenimiento, element.idGrupoMantenimiento)}
                    nodeId={"subMenu" + indice + index}
                    label={element.nombre}
                    key={element.nombre + indice + index}
                    itemId={`p${indice}${index}`}/>
            ))}
        </>
    );
};

export default MenuGroup;
